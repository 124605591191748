require("./bootstrap");

require("alpinejs");

import Vue from "vue";
import moment from "moment";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/es";
import ElementUI from "element-ui";
import VueRecaptcha from "vue-recaptcha";

import politic from "./politic";

Vue.use(ElementUI, { locale });
Vue.prototype.moment = moment;

Vue.prototype.truthty = function(data) {
    if (
        data === undefined ||
        data === null ||
        (!data && data !== 0) ||
        data === ""
    ) {
        return false;
    }
    if (Array.isArray(data)) {
        return data.length !== 0;
    }
    if (typeof data !== "string" && typeof data !== "number") {
        if (typeof data === "object") {
            return Object.keys(data).length !== 0;
        }
    }
    return true;
};

Vue.prototype.copy = function(x) {
    if (x != null && x != undefined) return JSON.parse(JSON.stringify(x));
    return x;
};
Vue.prototype.isEmailValid = function(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    return email == "" ? "" : reg.test(email) ? true : false;
};
Array.prototype.unique = (function(a) {
    return function() {
        return this.filter(a);
    };
})(function(a, b, c) {
    return c.indexOf(a, b + 1) < 0;
});

Vue.prototype.valuesFilter = function(data, props, action = null) {
    let names = [];
    let access = props.split(".");
    data.map(element => {
        let add = element;
        access.map(x => {
            add = add[x];
        });
        if (action != null && typeof action === "function") {
            names.push(action(add));
        } else {
            names.push(add);
        }
    });
    names = names.unique();
    names.sort();
    let values = [];
    names.map(name => {
        values.push({ text: name, value: name });
    });
    return values;
};
Vue.prototype.filterRow = function(e, action = null) {
    let access = e.split(".");
    return (value, row) => {
        let add = row;
        access.map(x => {
            add = add[x];
        });
        if (action != null && typeof action === "function") {
            return action(add) == value;
        }
        return add == value;
    };
};
Vue.prototype.formatDate = function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
};
Vue.prototype.objectToUrlPath = function(obj) {
    let str = "";
    for (let key in obj) {
        if (str != "") {
            str += "&";
        }
        str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
};
Vue.prototype.urlPathToObject = function() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    console.log(entries);
    let json = {};
    for (const entry of entries) {
        let values = entry[1].split(",");
        if (values.length > 1) {
            for (let i = 0; i < values.length; i++) {
                const element = values[i];
                let parseValue = parseInt(element, 10);
                if (!isNaN(parseValue)) values[i] = parseValue;
            }
            json[entry[0]] = this.copy(values);
        } else {
            const element = values[0];
            let parseValue = parseInt(element, 10);
            if (!isNaN(parseValue)) values[0] = parseValue;
            json[entry[0]] = this.copy(parseValue);
        }
        // json[entry[0]] = entry[1];
        // console.log(`${entry[0]}: ${entry[1]}`);
    }
    return json;
};
/**función que lleva al error en un formulario
 * @param {Array} rules contienen las reglas que tienen errores
 * @param {String} priority (opcional) valida si existe el elemento en rules,
 * si existe te lleva allí
 */
Vue.prototype.goToError = function(rules, priority = undefined) {
    let error;
    const keys = Object.keys(rules);
    if (this.truthty(priority) && keys.includes(priority)) {
        error = priority;
    } else {
        error = keys[0];
    }
    let element = document.getElementById(error);
    if (this.truthty(element)) element.scrollIntoView({ behavior: "smooth" });
};

Vue.prototype.validaRut = function(rutCompleto) {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    const dv = T => {
        let M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : "k";
    };
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    let tmp = rutCompleto.split("-");
    let digv = tmp[1];
    let rut = tmp[0];
    if (digv == "K") digv = "k";
    return dv(rut) == digv;
};

Vue.prototype.validaPhone = function(phone) {
    if (
        !/^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{1,3}[-\s\.]?[0-9]{4,6}$/im.test(
            phone
        )
    )
        return false;
    return true;
};
Vue.prototype.checkMobile = function() {
    let check = false;
    (function(a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};
// Vue.prototype.consentText =
// 	"<div>" +
// 	"<p>El ingreso de datos personales del usuario a través de la plataforma Valor Etico, y la aceptación expresa de la Política de Privacidad, que se describe a continuación, constituyen un requisito necesario para facilitar el contacto del usuario por parte de los clientes de Valor Etico, con el objetivo que las empresas clientes puedan iniciar una investigación y puedan comunicar los avances como dar respuesta a su reporte o denuncia ingresado a través de la plataforma situada en el dominio Valor Etico.com.</p>" +
// 	"<p>El usuario que decide brindar sus datos personales manifiesta conocer y aceptar lo siguiente:</p>" +
// 	"<div>" +
// 	"<ol>" +
// 	"<li>Valor Etico no se hará responsable por las acciones que sus clientes le den a la denuncia recibidas en la plataforma, ni las respuestas proporcionadas al usuario que ingresó la denuncia, ni a las consecuencias que las respuestas a ella, pudiera tener para el usuario, la persona objeto de su denuncia o para cualquier tercero relacionado con la denuncia, su seguimiento o acciones generadas por el cliente. En tal sentido, el denunciante manifiesta que no existe relación jurídica alguna  que lo vincule con Valor Etico, atento que Valor Etico se limita a recibir y a informar oportunamente al cliente de Valor Etico la denuncia, información, documentación y/o datos personales que él provea a través dela plataforma de Valor Etico.</li>" +
// 	"<li>Los destinatarios de los datos personales son exclusivamente los clientes de la plataforma Valor Etico, cuyo uso está definido para llevar adelante las investigaciones y reportes pertinentes, elaborar estadísticas generales. Valor Etico solo conserva los datos personales del usuario exclusivamente para fines estadísticos.</li>" +
// 	"<li>Valor Etico operará conforme con las disposiciones vigentes sobre Protección de los Datos Personales en Chile.</li>" +
// 	"<li>El usuario garantiza y responde en cualquier caso por la veracidad, exactitud, vigencia, autenticidad y certeza de los datos personales que ingresa a la plataforma de Valor Etico, por lo que asume la correspondiente responsabilidad en el caso que los mismos resulten ser inexactos. Valor Etico no asume ninguna responsabilidad en el caso de inexactitud de cualquiera de los datos ingresados por el usuario denunciante.</li>" +
// 	"<li>La obtención de datos personales de los usuarios a través de la plataforma de Valor Etico tiene por objeto exclusivo hacerles llegar a éstos información emitida por el cliente correspondiente, la cual no siempre es propia. Valor Etico no se responsabiliza por la información proveniente de terceros.</li>" +
// 	"<li>El usuario acepta y presta su consentimiento libre, expreso e informado para que dichos datos sean utilizados con las finalidades arriba mencionadas, y autoriza a que los mismos sea tratados, almacenados, recopilados o cedidos. La cesión de los datos personales de los usuarios será exclusivamente realizada a los clientes de Valor Eticoico, y tendrá exclusivamente como fin que los propios clientes inicien una investigación, soliciten ampliación de la denuncia al usuario y/o le realicen las preguntas o aclaraciones que consideren pertinentes, y le den la respuesta correspondiente al reporte o denuncia a través Valor Eticoero.</li>" +
// 	"<li>En cuanto a la cesión de los datos personales, Valor Etico se compromete con la protección de la privacidad, seguridad y confidencialidad de la información y que no será entregado a terceros distintos del cliente.</li>" +
// 	"<li>Valor Etico ha adoptado medidas de seguridad para proteger y asegurar la transmisión, ingreso y almacenamiento de los datos personales suministrados por los usuarios, de acuerdo con la naturaleza de los mismos, a fin de evitar adulteraciones o tratamientos no autorizados.</li>" +
// 	"<li>Valor Etico se reserva el derecho de modificar y/o revisar los términos y condiciones de esta Política de Privacidad en cualquier momento, notificando a los usuarios de tales modificaciones a través de la presente página.</li>" +
// 	"<li>El hecho que el usuario proporcione los datos que le sean requeridos, implica aceptación expresa de los términos expuestos en esta Política de Privacidad.</li>" +
// 	"<li>Sin perjuicio de lo dispuesto en el párrafo precedente, el usuario acepta y presta su consentimiento libre, expreso e informado con los términos de la presente Política de Privacidad, especialmente en todo aquello referido a la cesión de los datos personales.</li>" +
// 	"</ol>" +
// 	"</div>" +
// 	"</div>";
Vue.prototype.consentText = politic;

Vue.component("vue-recaptcha", VueRecaptcha);
Vue.component(
    "complaint-form-steps",
    require("./complaint-form/steps.vue").default
);
Vue.component("complaint-form", require("./complaint-form/index.vue").default);
Vue.component(
    "complaint-form-step1",
    require("./complaint-form/step1.vue").default
);
Vue.component(
    "complaint-form-step2a",
    require("./complaint-form/step2a.vue").default
);
Vue.component(
    "complaint-form-step2b",
    require("./complaint-form/step2b.vue").default
);
Vue.component(
    "complaint-form-step3",
    require("./complaint-form/step3.vue").default
);
Vue.component(
    "complaint-form-step4",
    require("./complaint-form/step4.vue").default
);
Vue.component(
    "complaint-report",
    require("./complaint-form/report.vue").default
);
Vue.component(
    "quick-complaint-form",
    require("./complaint-form/quick.vue").default
);

Vue.component("locals", require("./components/locals/index.vue").default);
Vue.component("countries", require("./components/countries/index.vue").default);
Vue.component("languages", require("./components/languages/index.vue").default);
Vue.component("settings", require("./components/settings/index.vue").default);
Vue.component(
    "settings-pages",
    require("./components/settings_pages/index.vue").default
);
Vue.component(
    "complaint-types",
    require("./components/complaint_types").default
);

Vue.component("places", require("./components/places").default);
Vue.component("people", require("./components/people").default);
Vue.component(
    "complaints-status",
    require("./components/complaints_status").default
);
Vue.component("activity-types", require("./components/activity_types").default);
Vue.component("corporatives", require("./components/corporatives").default);
Vue.component("users", require("./components/users").default);
Vue.component("complaints-list", require("./components/complaints").default);
Vue.component(
    "complaint-card",
    require("./components/complaints/card.vue").default
);
Vue.component(
    "card-body-asignament",
    require("./components/complaints/card_body_assignament.vue").default
);
Vue.component("dashboard", require("./components/dashboard/index.vue").default);

Vue.component("bar-chart", require("./charts/bar").default);
Vue.component("line-chart", require("./charts/line").default);
Vue.component("pie-chart", require("./charts/pieChart").default);
const app = new Vue({
    el: "#app"
});
